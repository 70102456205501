import {Route, Routes} from "react-router-dom";
import Signin from "./pages/Signin"
import Signup from "./pages/Signup"
import Playground from "./pages/Playground"
import Home from "./pages/admin/Home";
import Preview from "./pages/Preview";

const App = () => {
    return (
        <Routes>
            <Route path="/signup" element={<Signup/>}/>
            <Route path="/signin" element={<Signin/>}/>
            <Route path="/" element={<Playground/>}/>
            <Route path="/admin" element={<Home/>}/>
            <Route path="/preview/:garment_url/:model_url/:tryon_url" element={<Preview/>}/>
        </Routes>
    );
};

export default App;
