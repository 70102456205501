import React, {useEffect, useState, useRef} from "react";

import Sidebar from "../components/Sidebar";
import Navbar2 from "../components/Navbar2";
import TryonAI from "./TryonAI";
import Credits from "./Credits";
import Profile from "./Profile";
import landingbg2 from "../assets/bgGradient2.svg";
import {useNavigate} from "react-router-dom";
import {useAuthentication} from "../Authentication";
import {useSelector} from "react-redux";
import {selectAuthenticated} from "../selectors";
import Spinner from "../components/Spinner";


export default function Playground(props) {
    const [selectedOption, setSelectedOption] = useState("Try-on AI");
    const [options, setOptions] = React.useState({
        "Try-on AI": true,
        "credits": false,
        "profile": false,
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const navigate = useNavigate();
    const sidebarRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const authenticated = useSelector(selectAuthenticated);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setSidebarOpen(true);
            }else{
                setSidebarOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const closeSideBar = () => {
        setSidebarOpen(false);
    }

    const handleClickOutside = (event) => {
        console.log("clicked outside", sidebarRef.current)
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            console.log("clicked outside sidebar", isMobile)
            if (isMobile) {
                toggleSidebar(false);
            }
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarOpen]);

    const toggleSidebar = () => {
        setSidebarOpen(choice => !choice);
    };

    function handleOptionSelect(option, options) {
        console.log("Option selected:", option)
        setSelectedOption(option)
        setOptions(options)
    }

    function renderPlayground() {
        console.log("Selected option:", selectedOption)
        if (selectedOption === "Try-on AI") {
            return (<TryonAI/>)
        } else if (selectedOption === "credits") {
            return (<Credits/>)
        } else if (selectedOption === "profile") {
            return (<Profile/>)
        }
    }

    // useEffect(() => {
    //     if (!authenticated) {
    //         navigate("/signin");
    //     }else{
    //         setLoading(false);
    //     }
    // }, [authenticated,]);

    return (
        <div>
            <Sidebar ref={sidebarRef} handleOptionSelect={handleOptionSelect} isOpen={isSidebarOpen}
                     closeSideBar={closeSideBar} options={options}/>

            <div className="md:ml-64 h-screen" style={{backgroundImage: `url(${landingbg2})`, backgroundSize: 'cover'}}>
                <Navbar2 toggleSidebar={toggleSidebar} handleOptionSelect={handleOptionSelect} options={options}/>
                {renderPlayground()}
            </div>
        </div>
    )
}