import React, { useEffect, useState } from 'react';
import HRLineDashed from "../assets/hrline-dashed.svg";
import TryonLabsLogo from "../assets/TryonLabsLogo.png";
import closeIcon from "../assets/close-icon.svg";
import MailIcon from "../assets/mail_icon.svg";

const Sidebar = React.forwardRef((props, ref) => {
    const [isMobile,setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    function handleSelectOption(option) {
        if(props.isOpen && isMobile){
            props.closeSideBar();
        }
        let options1 = props.options;
        Object.keys(options1).forEach((key) => {
            if (key === option) {
                options1[key] = true;
            } else {
                options1[key] = false;
            }
        })
        console.log(options1)
        props.handleOptionSelect(option, options1);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <div ref={ref}>
            {
                props.isOpen &&
                (<div className='bg-opac-sidebar relative '>

                    <aside id="sidebar-multi-level-sidebar"
                           className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform translate-x-0 bg-white/opacity-70 shadow backdrop-blur-[30px]"
                           aria-label="Sidebar">
                           
                        <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                            <div
                                className='flex flex-col items-center justify-center w-full cursor-pointer pointer-events-auto'
                                onClick={() => handleSelectOption(Object.keys(props.options)[0])}>
                                <div className='flex w-full items-center justify-end'>
                                    <div
                                        className="text-neutral-400 text-sm font-normal font-proxima text-right mr-8">Tryon AI Beta
                                    </div>
                                </div>

                                <div className="flex justify-center gap-4 items-center ">
                                    <img src={TryonLabsLogo} alt="tryonlabs logo" className="w-12 h-auto"/>
                                    <div
                                        className="text-gray-900 text-xl font-medium font-Readex_pro ">Playground
                                    </div>
                                </div>

                                <div className='flex w-full items-center justify-end'>
                                    <div
                                        className="text-gray-900 text-sm text-right font-medium font-Readex_pro mr-8">By TryonLabs
                                    </div>
                                </div>
                            </div>

                            {props.options ? (
                                <div className="space-y-2 font-medium mt-4">

                                    {Object.keys(props.options).map((option, key) => (
                                        <div>
                                            <li className={`w-full py-3 px-4 justify-start items-center gap-2.5 inline-flex cursor-pointer ${props.options[option] ? "bg-gradient-to-r from-red-500 to-rose-600 rounded-lg" : ""}`}
                                                onClick={() => {
                                                    handleSelectOption(option)
                                                }}>
                                                <a href="#"
                                                   className={`${props.options[option] ? "text-rose-100" : "text-neutral-800 leading-normal"}`}><span
                                                    className="ms-3">{capitalizeFirstLetter(option)}</span></a>
                                            </li>
                                            <img src={HRLineDashed} className="mx-auto" alt="hr line"/>
                                        </div>
                                    ))}
                                </div>) : (<></>)}
                        </div>

                        <div className="text-black absolute bottom-5 left-5 text-sm cursor-pointer">
                            <div className="flex justify-start items-center gap-2">
                                <img src={MailIcon} alt="mail icon" className="w-6 h-auto"/>
                                <span className="underline">Contact Us</span>
                            </div>
                            <a className="text-gray-500" href="mailto:contact@tryonlabs.ai">contact@tryonlabs.ai</a>
                        </div>
                    </aside>

                    <div onClick={() => props.closeSideBar()} className='fixed left-60 top-4 z-40 md:hidden m-2'>
                        <img src={closeIcon}/>
                    </div>

                </div>)
            }
        </div>
    )
})

export default Sidebar;

